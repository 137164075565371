<template>
  <div id="preloader" class="preloader" v-if="loader"></div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Loader',
  computed: {
    ...mapGetters({
      loader: 'autoRia24/loader'
    })
  }
}
</script>
<style lang="sass" src="../loader.sass"></style>
